.a4-page {
  padding: 15px 40px;
  box-sizing: border-box;
  background: white;
}

.is-block {
  display: block !important;
}

.sski-c-print-modal__body {
  //overflow: hidden;
  max-width: 100% !important;
}

.sski-c-print-modal__link-out{
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 10px;
  svg {
    max-width: 32px;
  }
}

.sski-c-modal__print_button {
  min-width: 140px;
  max-width: 160px;
  height: 34px !important;
  border-color: black !important;
  box-shadow: unset !important;
  background: #fff !important;
  img {
    max-width: 18px;
  }
}
.ssski-c-print-modal__title {
  height: 64px;
}
.sski-c-print-category__title {
  border-bottom: 1px solid black;
  padding-bottom: 5px;
}
.sski-print-category__subtitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  vertical-align: middle;
}
#print-content {
  p {
    margin: 0.75rem 5% 0.75rem 0;
  }
}
.sski-c-print-modal {
  position: absolute;
  width: 80vw !important;
  height: 80vh !important;
}
.sski-c-print-modal.print-active, .loader-container {
  width: 100vw !important;
  height: 100vh !important;
  background: #fff;
  header, .sski-c-link-modal__close-button {
    display: none;
  }
  .sski-c-link-modal__card {
    min-height: 100% !important;
  }
  .a4-container {
    margin-top: 20px;
  }
  .a4-page {
    width: 21cm;
    display: block;
    margin: 0 auto;
    margin-bottom: 0.5cm;
    padding: 60px;
  }
}

@page {
  margin: 8mm auto;
  //margin-top: unset !important;
  -webkit-print-color-adjust: exact !important;
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    print-color-adjust:exact !important;
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    background: white !important;
    opacity: 1 !important;
  }
  .modal-background{
    visibility: hidden !important;
    opacity: 0 !important;
  }
  .a4-page {
    -webkit-print-color-adjust: exact !important;
    margin: 8mm auto;
    width: 190mm;
    height: 100% !important;
    position: relative;
    overflow: visible !important;
    background: white !important;
    box-shadow: unset !important;
    opacity: 1 !important;
    .ssski-c-print-modal__title {
      background: white !important;
      margin-bottom: 4mm;
      margin-top: unset !important;
      color: initial !important;
    }
    .sski-c-modal__print_button, .sski-c-modal__category-text {
      background: white !important;
      box-shadow: unset !important;
      opacity: 1 !important;
      color: initial !important;
      strong {
        color: initial !important;
      }
    }
  }
}