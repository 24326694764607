@use 'sass:map';
@use 'sass:string';

@import '../../main';

$card-variants: ('foundation', 'talent', 'elite', 'mastery', 'subphase');

$card-config: (
    color-foundation: map_get($app-theme-palette, blue),
    color-talent: map_get($app-theme-palette, yellow),
    color-elite: map_get($app-theme-palette, orange),
    color-mastery: map_get($app-theme-palette, primary),
    margin-foundation: 0 0 0 35%,
    margin-talent: 0 0 0 35%,
    margin-elite: 0,
    margin-mastery: 0 0 0 29px,
    margin-subphase: 0,
    margin-linear-gradient-subphase: 0,
    margin-linear-gradient-talent: 9.2vh 0 0 35%,
    margin-linear-gradient-elite: 13vh 0 0 0,
    margin-linear-gradient-foundation: 9.2vh 0 0 35%,
    margin-linear-gradient-mastery: 0 0 0 29px,
    border-linear-gradient-foundation: linear-gradient(
        332.71deg,
        rgba(57, 195, 212, 0) -9.36%,
        #39c3d4 97.55%
    ) border-box,
    border-linear-gradient-talent: linear-gradient(332.71deg, rgba(255, 204, 0, 0) -9.36%, #fc0 97.55%) border-box,
    border-linear-gradient-elite: linear-gradient(332.71deg, rgba(242, 148, 0, 0) -9.36%, #f29400 97.55%) border-box,
    border-linear-gradient-mastery: linear-gradient(332.71deg, rgba(226, 0, 26, 0) -9.36%, #e2001a 97.55%) border-box,
    height-subphase: 100%,
    position-subphase: relative,
    before-left-subphase: -29px,
    width-subphase: 100%,
    max-width-subphase: 366px,
    width-before-subphase: calc(100% + 30px),
);

.sski-c-card_container {
    min-height: 150px;
    min-width: 150px;
    border-radius: 20px;

    &--width-small {
        width: 25vw;

        &::before {
            width: 25vw;
        }
    }

    @each $variant in $card-variants {
        &--#{$variant} {
            border-style: solid;
            border-color: map_get($card-config, color-#{$variant}) !important;
            border-width: 0 0 0 29px;
            height: map_get($card-config, height-#{$variant});
            position: map_get($card-config, position-#{$variant});
            width: map_get($card-config, width-#{$variant});
            max-width: map_get($card-config, max-width-#{$variant});
        }

        &--margin-#{$variant} {
            margin: map_get($card-config, margin-#{$variant});

            &::before {
                margin: map_get($card-config, margin-linear-gradient-#{$variant});
            }
        }

        &--#{$variant}::before {
            content: '';
            position: absolute;
            bottom: 0;
            inset: 0;
            border-radius: 20px;
            border: 2px solid transparent;
            background: map_get($card-config, border-linear-gradient-#{$variant});
            mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            mask-composite: exclude;
            left: map_get($card-config, before-left-#{$variant});
            width: map_get($card-config, width-before-#{$variant});
        }
    }
}

.sski-c-card_container__content {
    margin: 1px 1px 1px 0;
    border-radius: 0 20px 20px 0;
    background: linear-gradient(334.76deg, rgba(221, 247, 255, 0) -25.19%, rgba(245, 245, 245, 0.63) 91.42%);
    min-height: 150px;
    width: 100%;
    backdrop-filter: blur(4px);
}

@media screen and (max-width: 768px) {
    .sski-c-card_container--phase {
            border: unset;
            border-top: 4px solid;
            border-radius: unset;
            &::before {
                display: none;
            }
        .sski-c-card_container__content {
            margin-top: 0;
        }
    }
    .sski-c-card_container--width-small {
        width: 100% !important;
        margin: unset !important;

    }
    .sski-c-dashboard-screen__legend-container--subphase {
        display: flex;
        flex-direction: column;
        top: 0;
        overflow-y: scroll;
        z-index: 1000;
        max-width: 100%;
        padding: 10px 5px;
        //padding-top: 45px;
        .sski-c-card_container--width-small {
            min-height: unset;
        }
    }

    .sski-info-card__description {
        p {
            font-weight: 400;
            font-size: 14px;
        }
    }
    .sski-info-card__icon-wraper {
        right: 5px;
        background: #F6F6F6;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        box-shadow: 0.59px 0.59px 3.54px 0px #00000040;
    }
}
.sski-card--skeleton_container {
    background: #EDEDED;
    border-radius: 20px;
    /*.skeleton-lines {
        background: map_get($app-theme-palette, grey) ;
    }*/
    div {
        min-height: 22px !important;
        border-radius: 50px !important;
        &:first-of-type {
            margin-left: 20px;
        }
        &:nth-of-type(odd){
            margin-right: 30px;
        }
    }
    .delete {
        position: absolute;
        left: -5px;
    }
}
