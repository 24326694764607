@use 'sass:map';
@use 'sass:string';

@import '../main';

$legend-variants: ('foundation', 'talent', 'elite', 'mastery', 'subphase');

$dashboard-config: (
    color-foundation: map_get($app-theme-palette, blue),
    color-talent: map_get($app-theme-palette, yellow),
    color-elite: map_get($app-theme-palette, orange),
    color-mastery: map_get($app-theme-palette, primary),
    absolute-top-foundation: 60%,
    absolute-top-talent: 60%,
    absolute-top-elite: 50%,
    absolute-top-mastery: 30%,
    absolute-top-subphase: 120px,
    absolute-left-foundation: 38%,
    absolute-left-talent: 57%,
    absolute-left-elite: 64%,
    absolute-left-mastery: 35%,
    absolute-left-subphase: 130px,
    circle-absolute-top-foundation: -7px,
    circle-absolute-top-talent: -7px,
    circle-absolute-top-elite: -7px,
    circle-absolute-top-mastery: 46%,
    circle-absolute-left-foundation: 0,
    circle-absolute-left-talent: 0,
    circle-absolute-left-elite: 0,
    circle-absolute-left-mastery: 100%,
    line-width-foundation: 14vw,
    line-width-talent: 14vw,
    line-width-elite: 9.7vw,
    line-width-mastery: 6.2vw,
    line-height-foundation: 9.2vh,
    line-height-talent: 9.2vh,
    line-height-elite: 13vh,
    line-height-mastery: 0,
    line-border-width-foundation: 3px 3px 0 0,
    line-border-width-talent: 3px 3px 0 0,
    line-border-width-elite: 3px 3px 0 0,
    line-border-width-mastery: 3px 0 0,
    display-subphase: flex,
    gap-subphase: 10px,
    width-subphase: 90vw,
);

@each $variant in $legend-variants {
    .sski-c-dashboard-screen__legend-container--#{$variant} {
        position: absolute;
        top: map_get($dashboard-config, absolute-top-#{$variant});
        left: map_get($dashboard-config, absolute-left-#{$variant});
        z-index: 100;
        display: map_get($dashboard-config, display-#{$variant});
        gap: map_get($dashboard-config, gap-#{$variant});
        width: map_get($dashboard-config, width-#{$variant});
    }

    .sski-c-dashboard-screen__legend-line--#{$variant} {
        width: map_get($dashboard-config, line-width-#{$variant});
        height: map_get($dashboard-config, line-height-#{$variant});
        border-width: map_get($dashboard-config, line-border-width-#{$variant});
        border-color: map_get($dashboard-config, color-#{$variant});
        border-style: solid;

        &::before,
        &::after {
            position: absolute;
            top: map_get($dashboard-config, circle-absolute-top-#{$variant});
            left: map_get($dashboard-config, circle-absolute-left-#{$variant});
            height: 15px;
            width: 15px;
            background: map_get($dashboard-config, color-#{$variant});
            content: '';
            border-radius: 50%;
        }
    }
}

.sski-c-dashboard-screen {
    min-height: 100vh;
    width: 100vw;
}

.sski-c-dashboard-screen__background {
    position: fixed;
    inset: 0;
    z-index: -1;
    width: 100dvw;
    height: 100dvh;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
}

.sski-c-dashboard-screen__top-bar {
    width: 100%;
    z-index: 100;
}

.sski-c-dashboard-screen__button {
    padding: 24px 24px 24px 34px;
    height: 72px !important;
    border-radius: 24px !important;

    &--small {
        padding: 4px 38px !important;
        height: 48px !important;
        border-radius: 40px !important;
    }
}

.sski-c-dashboard-screen__button_container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.sski-animation__pillar {
    position: absolute;
    cursor: pointer;
    z-index: 1200;
    //pointer-events: none;
}

.sski-animation__pillar--foundation {
    top: 38%;
    left: 14%;
    width: 21%;
    height: 44%;
}

.sski-animation__pillar--talent {
    top: 42%;
    left: 39%;
    width: 20%;
    height: 40%;
    background: red;
}

.subphase-pillar {
    position: absolute;
    transform-style: preserve-3d;
    height: 15%;
    min-width: 6%;
    cursor: pointer;
    
}

.f-pillar{
    
    height: 32%;
    min-width: 16%;
    top: 39%;
    left: 16%;
    transform: rotate3d(1, 1, 1, 42deg) skew(-39deg, -6deg);
    border-radius: 20px;
    transition: 0.2s ease-in;
    &.hover {
        top: 26%;
        transform: rotate3d(1, 1, 1, 40deg) skew(-39deg, -6deg);
    }
}

.t-pillar{
    height: 29%;
    min-width: 14%;
    top: 42%;
    left: 42%;
    transform: rotate3d(1, 1, 1, 42deg) skew(-37deg, -6deg);
    border-radius: 20px;
    transition: 0.2s ease-in;
    &.hover {
        top: 28%;
        transform: rotate3d(1, 1, 1, 40deg) skew(-36deg, -4deg);
    }
}

.e-pillar{
    
    height: 21%;
    min-width: 10%;
    top: 22%;
    left: 58%;
    transform: rotate3d(1, 1, 1, 42deg) skew(-37deg, -6deg);
    border-radius: 20px;
    transition: 0.2s ease-in;
    &.hover {
        top: 13%;
        transform: rotate3d(1, 1, 1, 40deg) skew(-36deg, -4deg);
    }
}
.m-pillar{
    
    height: 17%;
    min-width: 8%;
    top: 12%;
    right: 17%;
    transform: rotate3d(1, 1, 1, 42deg) skew(-37deg, -6deg);
    border-radius: 20px;
    transition: 0.2s ease-in;
    &.hover {
        top: 5%;
        transform: rotate3d(1, 1, 1, 40deg) skew(-36deg, -4deg);
    }
}

//.is-pointer {
//    cursor: pointer !important;
//}

.f1-pillar {
    top: 38%;
    left: 22%;
    transform: rotateX(41deg) rotateY(-25deg) skew(44deg, -11deg);
}

.f2-pillar {
    top: 28%;
    left: 15%;
    transform: rotateX(41deg) rotateY(-25deg) skew(46deg, -11deg);
}

.f3-pillar {
    top: 18%;
    left: 22%;
    transform: rotateX(46deg) rotateY(-14deg) skew(45deg, -25deg);
}


.t1-pillar {
    top: 41%;
    left: 46%;
    transform: rotateX(41deg) rotateY(-25deg) skew(46deg, -11deg);
}

.t2-pillar {
    top: 31%;
    left: 39%;
    transform: rotateX(41deg) rotateY(-25deg) skew(46deg, -11deg);
}

.t3-pillar {
    top: 22%;
    left: 46%;
    transform: rotateX(46deg) rotateY(-14deg) skew(45deg, -25deg);
}

.t4-pillar {
    top: 31%;
    left: 53%;
    transform: rotateX(41deg) rotateY(-25deg) skew(46deg, -11deg);
}

.e1-pillar {
    top: 22%;
    left: 61%;
    transform: rotateX(41deg) rotateY(-25deg) skew(44deg, -11deg);
    height: 9%;
    min-width: 4%;
}

.e2-pillar {
    top: 11%;
    left: 61%;
    height: 9%;
    min-width: 4%;
    transform: rotateX(41deg) rotateY(-25deg) skew(46deg, -11deg);
}

.m1-pillar {
    top: 6%;
    right: 19%;
    height: 9%;
    min-width: 4%;
    transform: rotateX(41deg) rotateY(-25deg) skew(46deg, -11deg);
}

.sski-animation__pillar--elite {
    top: 23%;
    left: 57%;
    width: 12%;
    height: 24%;
}

.sski-animation__pillar--mastery {
    top: 12%;
    left: 74%;
    width: 10%;
    height: 19%;
}

@media screen and (max-width: 767px) {
    .sski-c-dashboard-screen {
        overflow: hidden;
    }
    .sski-c-dashboard-screen__button {
        height: 34px !important;
        padding: 3px 16px 3px 16px !important;
    }
    .sski-c-dashboard-screen__button_arrow_img {
        width: 6px;
    }
    .sski-c-dashboard-screen__mobile-legend-container {
        top: unset;
        bottom: 67px;
        left: 0;
        width: 100%;
    }
    .sski-c-dashboard-screen__mobile-subphase-handler {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        height: 50px;
        background: transparent;
        margin-left: 71px;
        top: 0;
        position: fixed;
        z-index: 1;
        svg {
            max-height: 44px;
            object-fit: contain;
            position: absolute;
            right: 81px;
        }
    }
    .sski-c-card_container--subphase {
      height: auto !important;
      max-width: 100% !important;
        &:last-of-type {
            margin-bottom: 8px !important;
        }
    }
    //.sski-c-card_container--subphase.first{
    //    box-shadow: 65px -55px 0px rgb(247 247 274);
    //}
    .sski-c-dashboard-screen__button_container {
        display: flex;
        justify-content: center;
        bottom: 30px;
        .sski-c-dashboard-screen__button {
            min-height: 44px;
            line-height: 1;
            svg {
                max-width: 25px;
            }
        }
    }
    .sski-c-dashboard-screen__background {
        object-position: 0% 50%;
        object-fit: none;
    }
}

@media screen and (min-width: 768px) {
    .sski-c-card_container--subphase {
        pointer-events: none;
    }
    .sski-c-dashboard-screen__mobile-subphase-handler {
        order: 4;
        pointer-events: all;
        svg {
            cursor: pointer;
            max-width: 44px;
        }
    }
    .sski-c-dashboard-screen__legend-container--subphase {
        pointer-events: none;
    }
    .sski-c-dashboard-screen__sub-phase-title {
        top: 60px;
        left: 130px;
        width: calc(3 * 376px);
        text-align: center;
    }
}

@media screen and (min-width: 1024px)  and (max-width: 4500px) {
    .sski-c-dashboard-screen__legend-container--foundation{
        top: 42%;
        left: 33%;
    }
    .sski-c-dashboard-screen__legend-container--talent{
        top: 42%;
        left: 58%;
    }
    .sski-c-dashboard-screen__legend-container--elite{
        top: 23%;
        left: 68%;
    }
    .sski-c-dashboard-screen__legend-container--mastery{
        top: -5%;
        left: 32%;
    }
}
