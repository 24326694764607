.sski-c-categories-screen__tables_container {
    padding: 88px 0 100px 0;
    height: 100vh;
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
        display: none;
    }
}

.sski-c-categories-screen__background {
    min-height: 100vh;
    background-image: url('../../public/assets/ss-ftm-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
}
.sski-c-level-dossier__bg {
    background: #fff;
    min-height: 100vh;
    position: relative;
    z-index: 230;
}
