@import './customTheme';

html,
body,
#root {
    font-family: $family-cera !important;
}

button {
    background: linear-gradient(310.59deg, #f2f3f6 3.8%, #fff 119.99%) !important;
    border: 1px solid map_get($app-theme-palette, sand) !important;
    box-shadow: 0 0 10px 0 #24415d40 !important;

    &:hover {
        box-shadow: 0 0 5px 0 #24415d40 !important;
    }

    &:active {
        background:
            linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
            linear-gradient(310.59deg, #f2f3f6 3.8%, #fff 119.99%) !important;
        box-shadow: 0 0 2px 0 #24415d40 !important;
    }
}

.is-absolute {
    position: absolute;
}

*{
    /* Prevent any object from being highlighted upon touch event*/
    -webkit-tap-highlight-color: transparent;
}