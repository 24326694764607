@import '../../main';

@media screen and (max-width: 768px) {
  .sski-c-sidebar {
    z-index: 300;
    position: relative;
    height: 0;
  }
}

.sski-c-sidebar-navigation__background {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 84px;
}

.sski-c-sidebar-navigation__logo {
  position: absolute;
  top: 35px;
  left: 15px;
}

.sski-c-sidebar-navigation__sport {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sski-c-sidebar-navigation__sport-icon {
  width: 44px !important;
  height: 44px;
  border-radius: 15px;

  &:hover,
  &--active {
    background: map-get($app-theme-palette, primary);
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.sski-c-sidebar-navigation__sport-tooltip {
  padding: 4px 20px;
  position: absolute;
  left: 95px;
  width: 126px;
  height: 34px;
  background: linear-gradient(310.59deg, #f2f3f6 3.8%, #fff 119.99%);
  box-shadow: 0 0 6px 0 #24415d40;
  border-radius: 8px;
  z-index: 100;
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    top: 22%;
    left: -5px;
    border-style: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent #fff;
    display: block;
    width: 0;
  }
}

.sski-c-sidebar-navigation__sport-icon--active svg path {
  fill: #fff;
}

.sski-c-sidebar-navigation__squares-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 84px;
  height: 300px;
  overflow: hidden;
}

.sski-c-sidebar-navigation__square_light {
  position: absolute;
  bottom: -100px;
  left: -160px;
  width: 300px;
  height: 200px;
  background: #e6e7e8;
  z-index: 100;
  rotate: 60deg;
}

.sski-c-sidebar-navigation__square_dark {
  position: absolute;
  bottom: -70px;
  left: 0;
  width: 300px;
  height: 100px;
  background: #d1d3d4;
  rotate: 55deg;
}

.sski-c-sidebar-navigation__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  display: inline-block;
  margin-left: 5px;
}

.sski-c-sidebar-navigation-dropdown__trigger {
  border-top: unset;
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  position: fixed;
  top: 0;
  left: 0;
}

.sski-c-sidebar-navigation-dropdown_button {
  width: 75px;
  border-radius: 0 0 20px 20px !important;
  box-shadow: 0px 0px 6px 0px #24415D40;
}

.dropdown.active .sski-c-sidebar-navigation-dropdown {
  width: 197px;
}

.sski-c-sidebar-navigation__dropdown-menu {
  display: block;
  opacity: 0;

  position: absolute;   /* or float: left; width: 100%;*/
  left: 0;
  right: 0;

  transform: translateY(-50%);
  transition: all .4s ease-in-out;
  pointer-events: none;
}

.is-active .sski-c-sidebar-navigation__dropdown-menu {
  opacity: 1;
  transform: none;
  pointer-events: auto;
}

#nav-menu {

  .dropdown-content {
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 0px 6px 0px #24415D40;
  }
}