.sski-mobileanim-container {
  top: 10dvh;
  max-height: 70dvh;
  overflow: hidden;
}

.sski-mobileanim-container__frame {
  height: 65dvh;
  width: 75dvw;
  overflow: hidden;
}

.sski-mobileanim-container_image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  div, svg {
    width: 100%;
    height: 100%;
  }
}

.sski-mobileanim-container__trigger {
  position: absolute;
  cursor: pointer;
}

.sski-mobileanim-container__f_phase {
  width: 74%;
  height: 33%;
  bottom: 0;
  left: 5%;
}

.sski-mobileanim-container__t_phase {
  height: 23%;
  width: 61%;
  bottom: 40%;
  left: 19%;
}

.sski-mobileanim-container__e_phase {
  top: 14%;
  left: 16%;
  width: 43%;
  height: 19%;
}

.sski-mobileanim-container__m_phase {
  top: 0;
  right: 0;
  width: 37%;
  height: 17%;
}