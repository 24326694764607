@use 'sass:map';
@use 'sass:string';

@import '../../main';

$data-set-variants: ('foundation', 'talent', 'elite', 'mastery');

$data-set-config: (
    link-button-border-foundation: 1px solid map_get($app-theme-palette, blue),
    link-button-border-talent: 1px solid map_get($app-theme-palette, yellow),
    link-button-border-elite: 1px solid map_get($app-theme-palette, orange),
    link-button-border-mastery: 1px solid map_get($app-theme-palette, primary),
);

.sski-c-data-set {
    margin: 20px 5px 20px 5px;
}

.sski-c-data-set__title {
    left: 40px;
}

.sski-c-data-set__data-table {
    margin-top: 40px;
}

.sski-c-data-set__link-button {
    padding: 10px !important;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;

    @each $variant in $data-set-variants {
        &--#{$variant} {
            border: map_get($data-set-config, link-button-border-#{$variant}) !important;
            box-shadow: 0 0 6px 0 #24415d40 !important;
        }
    }
}

.sski-c-data-set__link-icon {
    width: 100% !important;
}

.sski-c-data-set__cell-content {
    height: 100% !important;

    a {
        text-decoration: underline !important;
    }

    &--padding-bottom {
        padding-bottom: 30px;
    }

    &--font-medium {
        font-size: 18px !important;
    }

    &-text {
        width: 100% !important;
    }
}

.sski-c-data-set__cell-content-merged {
    //box-shadow: -148px 2px 0 8px rgba(255, 255, 255, 1);
    //transform: translateX(-50%) translateY(-8px);
    text-align: left;
    background: #fff;
    height: 100%;
    padding: 10px 20px;
    .sski-c-data-set__cell-content {
        max-width: 560px;
    }
}
.sski-c-data-set__cell-content-merged-bottom{
    .sski-c-data-set__cell-content-merged {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
}

.sski-c-data-set_cell-content-hide {
    opacity: 0;
    visibility: hidden;
}

.merged_2 {
    width: 580px;
    transform: translateX(-50.5%);
    //border-radius: 20px;
}

.merged_3 {
    width: calc(290px * 3);
    transform: translateX(-67.1%);
    //text-align: center;
}
.merged_4 {
    width: calc(290px* 4);
    transform: translateX(-75.5%);
}

@media screen and (max-width: 768px) {
    .sski-c-data-set__cell-content--font-medium {
        font-size: 16px !important;
    }
}
