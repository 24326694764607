@import '../../main';

.sski-c-top-nav__print_active {
    .sski-c-top-navigation-bar__print_container {
        right: 0 !important;
        top: 0;
        width: 200px;
        height: 200px;
        background: #fff;
        .sski-c-top-navigation-bar__print_button {
            display: none !important;
        }
    }
}

.sski-c-top-navigation-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 88px;
    z-index: 100;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0) -101.14%, #f6f6f6 48.71%);
}

.sski-c-top-navigation-bar__navigation-line {
    top: 0;
    width: 100%;
    height: 88px;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0) -101.14%, #f6f6f6 48.71%);
}

.sski-c-top-navigation-bar__back_button {
    position: fixed;
    top: 30px;
    left: 30px;
    box-shadow: 1px 1px 6px 0 #24415d40 !important;
    z-index: 200 !important;
}

.sski-c-top-navigation-bar__dropdown_button {
    margin-top: 19px;
    box-shadow: 1px 1px 6px 0 #24415d40 !important;
}

.sski-c-top-navigation-bar__dropdown-menu {
    top: 75px;
    border-radius: 20px;
    z-index: 400 !important;
    box-shadow: 0 5px 10px 0 #00000040 !important;

    &::after {
        content: '';
        position: absolute;
        top: -19px;
        left: 45%;
        border-style: solid;
        border-width: 0 23px 23px;
        border-color: #fff transparent;
        display: block;
        width: 0;
        z-index: 1;
    }
}

.sski-c-top-navigation-bar__dropdown-content-frame {
    border-radius: 20px;
    overflow: hidden;
}

.sski-c-top-navigation-bar__dropdown-content {
    max-width: 100%;
    max-height: 100%;
    border-radius: 20px;
    box-shadow: 0 5px 10px 0 #00000040;
    overflow: auto;

    &::after {
        content: '';
        position: absolute;
        top: 4px;
        left: 2px;
        width: 95%;
        height: 55px;
        background: linear-gradient(4.89deg, rgba(255, 255, 255, 0) 3.94%, #fff 84.34%);
        border-radius: 20px 0 0 0;
    }
}

.sski-c-top-navigation-bar__dropdown-item {
    background-color: transparent !important;
    padding: 8px 20px;
    margin-top: 10px;
    border-radius: 40px;
    cursor: pointer;
    font-size: 18px;

    &:hover {
        background-color: map_get($app-theme-palette, grey-lighter) !important;
    }
}

.sski-c-top-navigation-bar__category-title {
    font-size: 20px;
    font-weight: 700;
}

.sski-c-top-navigation-bar__dropdown-content-sticky-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid map_get($app-theme-palette, grey);
    background-color: white;
    border-radius: 0 0 20px 20px;
}

.sski-c-top-navigation-bar__dropdown-content-button {
    height: 34px !important;
    width: 100%;
    border-radius: 24px;
    font-weight: 500;
}

.sski-c-top-navigation-bar__background-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0;
    background: #00000080;
    z-index: 300;
    &:before {
        content: '';
        position: fixed;
        width: 100vw;
        height: 88px;
        background: linear-gradient(0deg, rgb(128 123 123 / 18%) 0%, rgba(122, 122, 122, 1) 47%);
    }
}

.sski-c-top-navigation-bar-bar__category {
    &--margin-bottom {
        margin-bottom: 80px !important;
    }
}

.sski-c-top-navigation-bar__button-container {
    position: relative;
    z-index: 10;

    button {
        background: #fff !important;
        position: fixed;
        text-decoration: underline;
        border: none !important;
        box-shadow: none !important;

        &:hover {
            background: #fff !important;
            border: none !important;
            box-shadow: none !important;
        }

        &:active {
            background: #fff !important;
            border: none !important;
            box-shadow: none !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .sski-c-top-navigation-bar {
        //overflow-y: scroll;
        .dropdown.is-active {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
    .sski-c-top-navigation-bar__dropdown_button {
        height: 34px !important;
        margin-top: 16px;
        img {
            max-width: 12px;
        }
    }
    .sski-c-top-navigation-bar__back_button {
        height: 34px !important;
        min-height: 34px !important;
        top: 24px;
        left: 13px;
    }
    .sski-c-top-navigation-bar__dropdown-menu {
        top: 70px !important;
        left: unset !important;
    }
    .sski-c-top-navigation-bar__dropdown-content-frame {
        max-height: calc(100dvh - 90px);
        overflow-y: scroll;
    }
}

@media screen and (min-width: 768px) {
    .sski-c-top-navigation-bar__dropdown_button, .sski-c-top-navigation-bar__dropdown-content-button {
        height: 48px !important;
    }
    .sski-c-top-navigation-bar__back_button {
        height: 36px !important;
        width: 110px !important;
    }
    .sski-c-top-navigation-bar__dropdown-content {
        width: 480px !important;
        height: 82vh !important;
    }
    .sski-c-top-navigation-bar__dropdown-menu {
        top: 88px !important;
        left: -25% !important;
        width: 480px !important;
    }
    .sski-c-top-navigation-bar__category-title {
        font-size: 22px;
    }
    .sski-c-top-navigation-bar__dropdown-item {
        font-size: 20px;
    }
    .sski-c-top-navigation-bar-bar__category--margin-bottom {
            margin-bottom: 100px !important;
    }
}