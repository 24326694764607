.sski-c-top-navigation-bar__print_container {
  margin: 20px auto;
  top: 10px;
  position: absolute;
  right: 150px;
  z-index: 200;
}


.sski-c-top-navigation-bar__print_button {
  height: 34px !important;
}