// custom colors
$swiss-ski-red-90: #fecdd0;
$swiss-ski-red-700: #e30613;
$swiss-ski-red-800: #c00d0e;
$swiss-ski-red-900: #8a1002;
$swiss-ski-blue-90: #ccf9ff;
$swiss-ski-blue-100: #9dd5e2;
$swiss-ski-blue-200: #00a9c1;
$swiss-ski-blue-900: #004876;
$swiss-ski-yellow-90: #fff5cc;
$swiss-ski-yellow-200: #fc0;
$swiss-ski-green: #76b82a;
$swiss-ski-violet: #921c81;
$swiss-ski-orange-90: #ffebcc;
$swiss-ski-orange-200: #f39200;
$swiss-ski-sand: #baac90;
$swiss-ski-gray-10: #f6f6f6;
$swiss-ski-gray-50: #ededee;
$swiss-ski-gray-100: #e3e4e5;
$swiss-ski-gray-200: #dadada;
$swiss-ski-gray-900: #3c3c3b;

$app-theme-palette: (
    primary: $swiss-ski-red-700,
    grey: $swiss-ski-gray-200,
    grey-dark: $swiss-ski-gray-900,
    grey-light: $swiss-ski-gray-100,
    grey-lighter: $swiss-ski-gray-50,
    grey-lightest: $swiss-ski-gray-10,
    orange: $swiss-ski-orange-200,
    orange-light: $swiss-ski-orange-90,
    yellow: $swiss-ski-yellow-200,
    yellow-light: $swiss-ski-yellow-90,
    green: $swiss-ski-green,
    blue: $swiss-ski-blue-200,
    blue-lighter: $swiss-ski-blue-90,
    blue-light: $swiss-ski-blue-100,
    blue-dark: $swiss-ski-blue-900,
    purple: $swiss-ski-violet,
    red: $swiss-ski-red-700,
    red-light: $swiss-ski-red-90,
    red-dark: $swiss-ski-red-800,
    red-darker: $swiss-ski-red-900,
    link: $swiss-ski-blue-900,
    text: $swiss-ski-gray-900,
    sand: $swiss-ski-sand,
);

// typography
$family-cera: 'Cera Pro', sans-serif;

// override global Sass variables
@use 'bulma/sass' with (
    $family-primary: $family-cera,
    $primary: $swiss-ski-red-700,
    $grey-dark: $swiss-ski-gray-900,
    $grey: $swiss-ski-gray-200,
    $grey-light: $swiss-ski-gray-100,
    $grey-lighter: $swiss-ski-gray-50,
    $grey-lightest: $swiss-ski-gray-10,
    $orange: $swiss-ski-orange-200,
    $yellow: $swiss-ski-yellow-200,
    $green: $swiss-ski-green,
    $blue: $swiss-ski-blue-200,
    $purple: $swiss-ski-violet,
    $red: $swiss-ski-red-700,
    $link: $swiss-ski-blue-900,
    $text: $swiss-ski-gray-900,
    $custom-colors: (
        'red-dark': $swiss-ski-red-800,
        'red-darker': $swiss-ski-red-900,
        'blue-lighter': $swiss-ski-blue-90,
        'blue-light': $swiss-ski-blue-100,
        'blue-dark': $swiss-ski-blue-900,
        'sand': $swiss-ski-sand,
        'yellow-light': $swiss-ski-yellow-90,
        'orange-light': $swiss-ski-orange-90,
        'red-light': $swiss-ski-red-90,
    ),
    $input-shadow: 1px 1px 6px 0 #24415d40,
    $button-border-color: $swiss-ski-sand,
    $modal-background-background-color: #00000080

);

// local fonts
@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-Light.eot');
    src:
        local('Cera Pro Light'),
        local('CeraPro-Light'),
        url('fonts/CeraPro-Light.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-Light.woff2') format('woff2'),
        url('fonts/CeraPro-Light.woff') format('woff'),
        url('fonts/CeraPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-MediumItalic.eot');
    src:
        local('Cera Pro Medium Italic'),
        local('CeraPro-MediumItalic'),
        url('fonts/CeraPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-MediumItalic.woff2') format('woff2'),
        url('fonts/CeraPro-MediumItalic.woff') format('woff'),
        url('fonts/CeraPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-RegularItalic.eot');
    src:
        local('Cera Pro Regular Italic'),
        local('CeraPro-RegularItalic'),
        url('fonts/CeraPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-RegularItalic.woff2') format('woff2'),
        url('fonts/CeraPro-RegularItalic.woff') format('woff'),
        url('fonts/CeraPro-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-BlackItalic.eot');
    src:
        local('Cera Pro Black Italic'),
        local('CeraPro-BlackItalic'),
        url('fonts/CeraPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-BlackItalic.woff2') format('woff2'),
        url('fonts/CeraPro-BlackItalic.woff') format('woff'),
        url('fonts/CeraPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-Medium.eot');
    src:
        local('Cera Pro Medium'),
        local('CeraPro-Medium'),
        url('fonts/CeraPro-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-Medium.woff2') format('woff2'),
        url('fonts/CeraPro-Medium.woff') format('woff'),
        url('fonts/CeraPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-BoldItalic.eot');
    src:
        local('Cera Pro Bold Italic'),
        local('CeraPro-BoldItalic'),
        url('fonts/CeraPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-BoldItalic.woff2') format('woff2'),
        url('fonts/CeraPro-BoldItalic.woff') format('woff'),
        url('fonts/CeraPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-ThinItalic.eot');
    src:
        local('Cera Pro Thin Italic'),
        local('CeraPro-ThinItalic'),
        url('fonts/CeraPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-ThinItalic.woff2') format('woff2'),
        url('fonts/CeraPro-ThinItalic.woff') format('woff'),
        url('fonts/CeraPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-LightItalic.eot');
    src:
        local('Cera Pro Light Italic'),
        local('CeraPro-LightItalic'),
        url('fonts/CeraPro-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-LightItalic.woff2') format('woff2'),
        url('fonts/CeraPro-LightItalic.woff') format('woff'),
        url('fonts/CeraPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-Black.eot');
    src:
        local('Cera Pro Black'),
        local('CeraPro-Black'),
        url('fonts/CeraPro-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-Black.woff2') format('woff2'),
        url('fonts/CeraPro-Black.woff') format('woff'),
        url('fonts/CeraPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-Bold.eot');
    src:
        local('Cera Pro Bold'),
        local('CeraPro-Bold'),
        url('fonts/CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-Bold.woff2') format('woff2'),
        url('fonts/CeraPro-Bold.woff') format('woff'),
        url('fonts/CeraPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-Regular.eot');
    src:
        local('Cera Pro Regular'),
        local('CeraPro-Regular'),
        url('fonts/CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-Regular.woff2') format('woff2'),
        url('fonts/CeraPro-Regular.woff') format('woff'),
        url('fonts/CeraPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Cera Pro';
    src: url('fonts/CeraPro-Thin.eot');
    src:
        local('Cera Pro Thin'),
        local('CeraPro-Thin'),
        url('fonts/CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
        url('fonts/CeraPro-Thin.woff2') format('woff2'),
        url('fonts/CeraPro-Thin.woff') format('woff'),
        url('fonts/CeraPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
