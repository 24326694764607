@use 'sass:map';
@use 'sass:string';

@import '../../main';

$link-view-modal-variants: ('foundation', 'talent', 'elite', 'mastery', 'default');

$link-view-modal-config: (
    header-background-foundation: map_get($app-theme-palette, blue),
    header-background-talent: map_get($app-theme-palette, yellow),
    header-background-elite: map_get($app-theme-palette, orange),
    header-background-mastery: map_get($app-theme-palette, primary),
    header-color-foundation: white,
    header-color-talent: white,
    header-color-elite: white,
    header-color-mastery: white,
    header-color-default: map_get($app-theme-palette, grey-dark),
);

.sski-c-link-modal {
    position: absolute;
    width: 80vw !important;
    height: 80vh !important;
}

.sski-c-link-modal__card {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px;
    box-shadow: 0 5px 10px 0 #00000040;
}

.sski-c-link-modal__head {
    padding: 8px 0 !important;
    height: 48px !important;
    width: 100% !important;
    background-color: map_get($app-theme-palette, grey-lightest) !important;
    font-size: 24px !important;
    font-weight: 700 !important;

    @each $variant in $link-view-modal-variants {
        &--#{$variant} {
            background-color: map_get($link-view-modal-config, header-background-#{$variant}) !important;
        }
    }
}

.sski-c-link-modal__head_title {
    @each $variant in $link-view-modal-variants {
        &--#{$variant} {
            color: map_get($link-view-modal-config, header-color-#{$variant}) !important;
        }
    }
}

.sski-c-link-modal__body {
    overflow: hidden;
    max-width: 100% !important;
}

.sski-c-link-modal__close-button {
    position: absolute;
    top: -30px;
    right: -60px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
        background: transparent !important;
        box-shadow: none !important;
    }

    &:active {
        background: transparent !important;
        box-shadow: none !important;
    }
}

.sski-c-link-modal__link-out {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 32px;
}

.sski-c-link-modal__link-out_icon {
    width: 100%;
    height: 100%;

    @each $variant in $link-view-modal-variants {
        &--#{$variant} {
            fill: map_get($link-view-modal-config, header-color-#{$variant}) !important;
        }
    }
}
