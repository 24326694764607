@use 'sass:map';
@use 'sass:string';

@import '../../main';

$cell-variants: ('title', 'regular');
$cell-position-variants: ('top', 'top-left', 'top-right', 'bottom', 'bottom-left', 'bottom-right');
$cell-color-variants: ('foundation', 'talent', 'elite', 'mastery');

$cell-config: (
    border-width-top: 1px,
    border-width-bottom: 0 1px 1px 1px,
    border-width-top-left: 1px,
    border-width-top-right: 0 1px 1px 1px,
    border-width-bottom-left: 0 1px 1px 1px,
    border-width-bottom-right: 0 1px 1px 1px,
    box-shadow-foundation: 0 4px 10px 0 rgba(map_get($app-theme-palette, blue), 0.25),
    box-shadow-talent: 0 4px 10px 0 rgba(map_get($app-theme-palette, yellow), 0.25),
    box-shadow-elite: 0 4px 10px 0 rgba(map_get($app-theme-palette, orange), 0.25),
    box-shadow-mastery: 0 4px 10px 0 rgba(map_get($app-theme-palette, primary), 0.25),
    border-radius-top: 20px 20px 0 0,
    border-radius-bottom: 0 0 20px 20px,
    border-radius-top-left: 20px 0 0 0,
    border-radius-bottom-left: 0 0 0 20px,
    border-radius-top-right: 0 20px 0 0,
    border-radius-bottom-right: 0 0 20px 0,
    color-foundation: map_get($app-theme-palette, blue),
    color-talent: map_get($app-theme-palette, yellow),
    color-elite: map_get($app-theme-palette, orange),
    color-mastery: map_get($app-theme-palette, primary),
    color-selected-foundation: map_get($app-theme-palette, blue-lighter),
    color-selected-talent: map_get($app-theme-palette, yellow-light),
    color-selected-elite: map_get($app-theme-palette, orange-light),
    color-selected-mastery: map_get($app-theme-palette, red-light),
);

.sski-c-data-cell {
    //padding: 3px;
    width: 290px;
    background: #fff;
    flex-shrink: 0;
    white-space: initial;
    word-break: break-word;

    @each $variant in $cell-color-variants {
        &--background-#{$variant} {
            background: map_get($cell-config, color-#{$variant});
        }

        &--background-selected-#{$variant} {
            background: map_get($cell-config, color-selected-#{$variant});
                .sski-c-data-set__cell-content-merged {
                    background: map_get($cell-config, color-selected-#{$variant});
                }
        }

        &--box-shadow-#{$variant} {
            box-shadow: map_get($cell-config, box-shadow-#{$variant});
        }
    }

    @each $position in $cell-position-variants {
        &--border-radius-#{$position} {
            border-radius: map_get($cell-config, border-radius-#{$position});
        }
    }

    &--border-bottom {
        border-bottom: map_get($app-theme-palette, grey) 1px dashed;
    }

    &--min-height {
        min-height: 50px;
    }
}
