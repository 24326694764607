.sski-c-bottom-navigation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 120px;
    z-index: 100;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f6f6f6 48%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sski-c-bottom-navigation-bar__img-box {
    width: 110px;
    height: 80px;
    img {
        height: 400%;
        width: 400%;
        vertical-align: middle;
        transform: scale(0.25);
        transform-origin: 0 0;
        min-width: 440px;
        min-height: 320px;
    }
}

@media screen and (max-width: 767px) {
    .sski-c-bottom-navigation-bar {
        box-shadow: 0px -2px 9px 0px #0000001F;
        height: 67px;
        z-index: 1310;
        gap: 5px;
        img {
            max-height: 42px;
        }
    }
    .sski-c-bottom-navigation-bar__icon-selected-f {
        g:first-of-type rect {
            fill: #00a9c1;
        }
    }
    .sski-c-bottom-navigation-bar__icon-selected-t {
        g:first-of-type rect {
            fill: #fc0;
        }
    }
    .sski-c-bottom-navigation-bar__icon-selected-e {
        g:first-of-type rect {
            fill: #f39200;
        }
        #e2-mob-nav path{
            fill: #f39200;
        }
    }

    .sski-c-bottom-navigation-bar__icon-selected-m {
        g:first-of-type rect {
            fill: #e30613;
        }
    }
}
