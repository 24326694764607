@import '../../main';

@media screen and (max-width: 767px) {
    .sski-c-translation-dropdown__globe_icon {
        width: 16px;
        margin-right: 4px;
    }
    .sski-c-translation-dropdown__arrow {
        width: 13px;
    }
}

@media screen and (min-width: 768px){
    .sski-c-translation-dropdown__dropdown {
        margin-top: 30px;
    }
    .sski-c-translation-dropdown__button {
        height: 36px !important;
    }
}

.sski-c-translation-dropdown__button {
    height: 34px !important;
    box-shadow: 1px 1px 6px 0 #24415d40 !important;
}

.sski-c-translation-dropdown__dropdown-menu-item {
    cursor: pointer;

    &:hover {
        background: map-get($app-theme-palette, grey-lightest);
    }

    &--active {
        background: map-get($app-theme-palette, grey-lighter);
    }
}
